<template>
  <div>
    <el-dialog
      width="70rem"
      visible
      custom-class="add-contact-modal rounded"
      :show-close="false"
      @close="$emit('close')"
    >
      <template #title>
        <div class="header d-flex justify-content-between p-0 pb-0">
          <div>
            <h2>{{ $t('tasks.addContactModal.title') }}</h2>
            <p>{{ $t('tasks.addContactModal.subTitle') }}</p>
          </div>
          <Button type="icon" class="p-0" @click="$emit('close')">
            <CloseIcon />
          </Button>
        </div>
      </template>
      <div>
        <h3 class="mb-5">{{ $t('tasks.addContactModal.existingContacts', { supplierName: supplier.name }) }}</h3>
      </div>
      <Table
        custom-class="contactSets-table"
        :data="tableData"
        :columns="columns"
        :cell-class="(rowIndex) => activeRowActionsIndex === rowIndex && 'bg-secondary'"
        show-index
        rounded
        border
        @row-click="handleTableRowClick"
      >
        <template #cell-actions="{ rowData, rowIndex }">
          <Button
            type="link"
            class="p-0 m-0 actions-btn"
            :disabled="rowData.selectDisabled"
            @click.stop="selectContact(rowData.id)"
            @visible-change="(isVisible) => (activeRowActionsIndex = isVisible ? rowIndex : -1)"
          >
            {{ $t('tasks.addContactModal.table.actions.chooseThisContact') }}
          </Button>
        </template>
      </Table>
      <template #footer>
        <Button @click="$emit('open-create-contact-set-modal')">{{
          $t('tasks.addContactModal.createNewContact')
        }}</Button>
      </template>
    </el-dialog>
    <ContactSetModal
      v-if="!!contactSetDetails"
      :clarity-business-id="clarityBusiness.id"
      :contact="contactSetDetails"
      :show-actions="false"
      @close="() => (contactSetDetails = null)"
    ></ContactSetModal>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { reject, isNil, either, isEmpty } from 'ramda';

import { useTenancy } from '@/modules/auth';
import { CloseIcon } from '@/assets/icons';
import { Button, Table } from '@/modules/core';
import { useBusinesses } from '@/modules/businesses/compositions/businesses';
import { ContactSetModal, useContactSetPatch } from '@/modules/contactSet';
import { useContactSet } from '@/modules/contactSet/composition/contactSet';
import { CONTACT_TYPE } from '@/modules/contactSet/components/contactSetInfo';

const TABLE_HEADERS = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  ROLE: 'role',
  GENDER: 'gender',
  EMAIL: 'email',
  PHONE: 'phone',
  ACTIONS: 'actions',
};

export default {
  name: 'AddContactModal',
  components: { CloseIcon, Button, Table, ContactSetModal },
  props: {
    contactSets: { type: Array, default: () => [] },
    supplier: { type: Object, required: true },
  },
  setup() {
    const { currentTenant } = useTenancy();
    const { patchContactSet } = useContactSetPatch();
    const { businesses } = useBusinesses(
      computed(() => ({
        first: 1,
        after: 0,
        capabilities: {
          serviceProvider: true,
        },
      }))
    );
    const { contactSet: contactSetDetails, fetchContactSet: fetchContactSetDetails } = useContactSet();

    const clarityBusiness = computed(() => businesses.value.businesses.nodes[0]);

    return {
      currentTenant,
      activeRowActionsIndex: ref(-1),
      showContactSetCreate: ref(false),
      clarityBusiness,
      contactSetDetails,
      fetchContactSetDetails,
      patchContactSet,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t(`contacts.table.headers.${TABLE_HEADERS.FIRST_NAME}`),
          key: TABLE_HEADERS.FIRST_NAME,
          width: '7rem',
        },
        {
          header: this.$t(`contacts.table.headers.${TABLE_HEADERS.LAST_NAME}`),
          key: TABLE_HEADERS.LAST_NAME,
          width: '8rem',
        },
        {
          header: this.$t(`contacts.table.headers.${TABLE_HEADERS.ROLE}`),
          key: TABLE_HEADERS.ROLE,
          width: '8rem',
        },
        {
          header: this.$t(`contacts.table.headers.${TABLE_HEADERS.GENDER}`),
          key: TABLE_HEADERS.GENDER,
          width: '5rem',
        },
        {
          header: this.$t(`contacts.table.headers.${TABLE_HEADERS.EMAIL}`),
          key: TABLE_HEADERS.EMAIL,
          width: '15rem',
        },
        {
          header: this.$t(`contacts.table.headers.${TABLE_HEADERS.PHONE}`),
          key: TABLE_HEADERS.PHONE,
          width: '10rem',
          customClass: 'phone-column',
        },
        {
          header: '',
          key: TABLE_HEADERS.ACTIONS,
          width: '10rem',
          customClass: 'p-0',
        },
      ];
    },
    tableData() {
      return this.contactSets.map((contactSet) => ({
        id: contactSet.id,
        [TABLE_HEADERS.FIRST_NAME]:
          contactSet.type !== CONTACT_TYPE.OFFICE ? contactSet.firstName : contactSet.officeName,
        [TABLE_HEADERS.LAST_NAME]: contactSet.lastName,
        [TABLE_HEADERS.ROLE]: contactSet.worksAt[0].role || '-',
        [TABLE_HEADERS.GENDER]: contactSet.gender && this.$t(`contactsSet.table.rows.gender.${contactSet.gender}`),
        [TABLE_HEADERS.EMAIL]: contactSet.emails?.[0]?.email || '-',
        [TABLE_HEADERS.PHONE]: contactSet.phones?.[0]?.number || '-',
        selectDisabled: contactSet.worksAt.some((workplace) =>
          workplace.worksWith.some((worksWith) => worksWith.id === this.currentTenant.id)
        ),
      }));
    },
  },
  methods: {
    async handleTableRowClick(index) {
      await this.fetchContactSetDetails({ id: this.contactSets[index].id });
    },
    async selectContact(contactSetId) {
      const selectedContactSet = this.contactSets.find(({ id }) => id === contactSetId);
      const putParams = reject(either(isNil, isEmpty), {
        businessId: this.clarityBusiness.id,
        type: selectedContactSet.type,
        firstName: selectedContactSet.firstName,
        lastName: selectedContactSet.lastName,
        officeName: selectedContactSet.officeName,
        gender: selectedContactSet.gender,
        note: selectedContactSet.note,
        phones: selectedContactSet.phones?.map(({ number, extension, countryCode }) =>
          reject(either(isNil, isEmpty), {
            number,
            extension,
            countryCode,
          })
        ),
        emails: selectedContactSet.emails?.map(({ email }) => ({ email })),
        worksAt: selectedContactSet.worksAt.map((workplace) => {
          const worksWith = workplace.worksWith.map((worksWith) => worksWith.id);
          if (workplace.id === this.supplier.id) worksWith.push(this.currentTenant.id);
          return reject(either(isNil, isEmpty), {
            id: workplace.id,
            role: workplace.role,
            worksWith,
          });
        }),
      });

      await this.patchContactSet({ id: selectedContactSet.id, data: putParams });
      this.$emit('close');
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.header {
  .title {
    font-weight: 700;
    font-size: 1.5em;
  }
}

tr {
  .actions-btn {
    visibility: hidden;
  }

  &:hover .actions-btn {
    visibility: visible;
  }
}

:deep(.phone-column > div > p) {
  direction: ltr;
}
</style>
