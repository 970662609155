<template>
  <div class="d-flex align-items-center justify-align-content-center bg-primary text-white rounded-1">
    <div class="py-2 px-3 action" @click="$emit('update-task')">
      <p class="fw-bold">{{ $t('tasks.taskLayout.updateAndMoveToNextTask') }}</p>
    </div>
    <div class="divider" />
    <el-dropdown class="action" trigger="click" placement="top-start" @command="(method) => method()">
      <div class="text-white m-2"><ChevronIcon /></div>
      <el-dropdown-menu>
        <slot name="items"></slot>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { ChevronIcon } from '@/assets/icons';
export default {
  components: { ChevronIcon },
};
</script>

<style lang="scss" scoped>
.divider {
  background: #0d50d4;
  width: 2px;
  height: 100%;
}

.action {
  height: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: #6596f7;
    border-radius: 4px;
  }
}
</style>
