<template>
  <div class="d-flex flex-column w-100">
    <el-form ref="form" :model="taskForm" label-position="top" :show-message="false" class="d-flex flex-column gap-4">
      <div>
        <el-form-item
          class="mb-2 business-radio"
          :label="$t('requests.requestsButton.popover.intendedFor')"
          prop="targetBusiness"
          required
        >
          <el-radio-group
            v-model="taskForm.targetBusiness"
            class="d-flex flex-column w-100 gap-2"
            :class="{ 'radio-red-border': targetBusinessValidationError }"
          >
            <el-radio
              v-for="(business, index) in targetBusinessOptions"
              :key="index"
              :label="business"
              class="pt-1 mx-0 d-flex column align-items-center"
            >
              <p class="text-typography-primary">
                {{ business?.name }}
              </p>
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div v-if="templates?.length">
        <el-form-item
          :label="$t('requests.requestsButton.popover.templateType')"
          prop="templateType"
          class="mb-2"
          required
        >
          <el-select v-model="taskForm.templateType" class="w-100 mt-1 template-select" @change="onTemplateChange">
            <el-option
              v-for="template in templates"
              :key="`template-${template.type}`"
              :label="template.title"
              :value="template.type"
              class="mb-0 w-100"
            />
          </el-select>
        </el-form-item>
      </div>
      <div>
        <el-form-item :label="$t('requests.requestsButton.popover.requestContent')" prop="details" required>
          <el-input
            v-model="taskForm.details"
            class="details-area mt-1"
            resize="none"
            type="textarea"
            :readonly="taskForm.templateType && taskForm.templateType !== 'other'"
            :autosize="{ minRows: 4, maxRows: 6 }"
          />
        </el-form-item>
      </div>
    </el-form>
    <div class="d-flex justify-content-end">
      <Button type="secondary" @click="$emit('close')">{{ $t('requests.requestsButton.popover.cancel') }}</Button>
      <Button @click="createRequest">{{ $t('requests.requestsButton.popover.createRequest') }}</Button>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from 'vue';

import { Button } from '@/modules/core';

export default {
  name: 'CreateRequestForm',
  components: { Button },
  props: {
    taskId: { type: String, required: true },
    targetBusinessOptions: { type: Array, default: () => [] },
    templates: { type: Array, required: false, default: () => [] },
  },
  emits: ['template-selected', 'create-request'],
  setup(props) {
    const template = props.templates?.find((template) => template.selected);
    return {
      requestsPopover: ref(false),
      targetBusinessValidationError: ref(false),
      taskForm: reactive({
        targetBusiness: null,
        templateType: template?.type || null,
        details: template?.text || '',
        templateData: template?.missingData || null,
      }),
    };
  },
  watch: {
    'taskForm.targetBusiness'() {
      this.targetBusinessValidationError = false;
    },

    templates: {
      handler() {
        const template = this.templates?.find((t) => t.selected);
        if (template) {
          this.taskForm.details = template.text;
          this.taskForm.templateType = template.type;
          this.taskForm.templateData = template.missingData;
        }
      },
      deep: true,
    },
  },

  methods: {
    onTemplateChange(type) {
      this.$emit('template-selected', type);
    },
    createRequest() {
      this.$refs.form.validate(async (valid, errors) => {
        if (valid) {
          this.$emit('create-request', {
            targetBusinessId: this.taskForm.targetBusiness.id,
            details: this.taskForm.details,
            template: this.taskForm.templateType,
            templateData: this.taskForm.templateData,
          });
        } else {
          if (errors.targetBusiness) {
            this.targetBusinessValidationError = true;
          }
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.details-area {
  word-break: break-word;
}

.radio-red-border ::v-deep .el-radio__inner {
  border-color: #e52044;
}

:deep(.el-select .el-input .el-input__inner) {
  height: 32px;
  line-height: 32px;
}

:deep(.business-radio) {
  .el-form-item__label {
    margin-bottom: 5px;
  }
}

:deep(label.el-form-item__label) {
  line-height: 20px;
  font-weight: 500;
  font-size: 14px;
}
</style>
