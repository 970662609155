<template>
  <el-dropdown-item :disabled="!taskCloseable" :command="command">
    <CheckCircleIcon /> <slot name="text"></slot>
  </el-dropdown-item>
</template>

<script>
import { CheckCircleIcon } from '@/assets/icons';

export default {
  components: { CheckCircleIcon },
  props: {
    taskCloseable: { type: Boolean, default: true },
    command: { type: Function, required: true },
  },
};
</script>
