<template>
  <div class="matching-table-wrapper">
    <el-table
      class="matching-table"
      :data="billings"
      :class="{ 'text-disabled': tableDisabled }"
      @row-click="$emit('row-click', $event)"
    >
      <el-table-column v-slot="{ $index }" width="30">
        <div v-if="tableDisabled" class="text-disabled">
          <div class="radio-btn-disabled radio-btn" />
        </div>

        <div v-else class="radio-btn-wrapper">
          <div class="radio-btn" :class="{ selected: $index === selectedIndex }" />
        </div>
      </el-table-column>
      <el-table-column v-slot="{ row: { date } }" width="90" :label="$t('creditMatchModal.table.common.label.date')">
        {{ formatDate(date) }}
      </el-table-column>
      <el-table-column
        v-slot="{
          row: {
            source: { document },
          },
        }"
        width="212"
        :label="$t('commons.reference')"
      >
        <TruncatedText>
          <span v-if="tableDisabled" class="text-disabled">
            {{ document && document.type ? $t(`document.exports.schema.type.shortName.${document.type}`) : '' }}
            {{ document.documentNumber }}
          </span>
          <a v-else href="" @click.prevent.stop="$emit('document-click', document.id)">
            {{ document && document.type ? $t(`document.exports.schema.type.shortName.${document.type}`) : '' }}
            {{ document.documentNumber }}
          </a>
        </TruncatedText>
      </el-table-column>

      <el-table-column v-slot="{ row: { netAmount } }" :label="$t('commons.netAmount')">
        {{ formatMoney(netAmount) }}
      </el-table-column>

      <el-table-column v-slot="{ row: { totalAmount } }" :label="$t('commons.totalAmount')">
        {{ formatMoney(totalAmount) }}
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { TruncatedText } from '@/modules/core/components';
import { useCurrency } from '@/modules/core/compositions/money-currency';

export default {
  components: { TruncatedText },
  props: {
    billings: { type: Array, default: () => [] },
    selectedIndex: { type: Number, default: -1 },
    tableDisabled: { type: Boolean, default: false },
  },
  setup() {
    const { formatCentsToCurrency } = useCurrency();
    return { formatCentsToCurrency };
  },
  methods: {
    formatMoney(value) {
      return this.formatCentsToCurrency(Math.abs(Number(value))) ?? '-';
    },
    formatDate(ms) {
      return ms
        ? new Date(ms).toLocaleDateString(this.$i18n.locale, {
            year: '2-digit',
            month: 'numeric',
            day: 'numeric',
          })
        : null;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.text-disabled {
  &:hover {
    cursor: not-allowed;
  }
  ::v-deep th td {
    padding: 2px;
  }
  ::v-deep .is-leaf {
    color: #c4c6cf !important;
  }
}

.matching-table-wrapper {
  display: grid;
  border: 1px solid $outline;
  border-radius: 6px;
  margin-bottom: 0;

  .matching-table {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    .fully-matched {
      color: $success;
    }

    .radio-btn-wrapper {
      height: 23px;
      width: 16px;
      display: flex;
      align-items: center;
    }

    .radio-btn {
      height: 16px;
      width: 16px;
      border: 1px solid $primary;
      box-sizing: border-box;
      border-radius: 36px;

      &-disabled {
        border: 1px solid #c4c6cf;
      }

      &.selected {
        border: 1px solid rgba(0, 0, 0, 0.3);
        background: $primary;

        &::after {
          content: '';
          position: absolute;
          background: $white;
          border-radius: 29px;

          $radius: 7px;
          height: $radius;
          width: $radius;
          transform: translate(3.5px, 3.5px);

          [dir='rtl'] & {
            transform: translate(-3.5px, 3.5px);
          }
        }
      }
    }

    ::v-deep td th {
      padding: 2px;
    }
    ::v-deep .el-table__expanded-cell {
      padding: 0;
    }
    ::v-deep .is-leaf {
      color: #131416;
      > .cell {
        font-weight: 500 !important;
      }
    }
    ::v-deep .el-table__expand-column,
    .el-table_1_column_1 {
      .cell {
        padding: 0;
      }
    }

    ::v-deep .el-table_1_column_1 {
      .cell {
        height: 100%;
      }
    }
  }
}
</style>
