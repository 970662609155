<template>
  <el-dialog visible custom-class="rounded confirm-modal" width="400px" @close="$emit('cancel')">
    <template #title>
      <div class="d-flex justify-content-start align-items-center">
        <h2 class="title px-2">{{ title }}</h2>
      </div>
    </template>
    <div>
      {{ content }}
    </div>

    <template #footer>
      <div class="d-flex justify-content-end align-items-center">
        <Button type="secondary" @click="$emit('cancel')">
          {{ cancel }}
        </Button>
        <Button type="primary" @click="$emit('confirm')">
          {{ confirm }}
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';

export default {
  components: { Button },
  props: {
    title: { type: String, required: true },
    content: { type: String, required: true },
    confirm: { type: String, required: true },
    cancel: { type: String, required: true },
  },
};
</script>

<style scoped lang="scss">
:deep(.confirm-modal) {
  max-height: 225px;
  display: flex;
  flex-direction: column;
  .el-dialog__header {
    padding: 1rem;
  }

  .el-dialog__body {
    padding: 0rem 1rem 1rem 1rem;
  }

  .el-dialog__footer {
    padding: 1rem;
  }

  .title {
    font-size: 1.25rem;
    height: auto;
  }

  .el-dialog__headerbtn:hover > i {
    color: inherit;
  }
}
</style>
